import React from "react";
import { Route, RouteProps } from "react-router-dom";
import CollovGbt from "../pages/collov-gbt";

import Root from "./Root";
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

export interface RoutesProps {
    path: RouteProps["path"];
    name?: string;
    component?: RouteProps["component"];
    route?: any;
    exact?: RouteProps["exact"];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

const rootRoute: RoutesProps = {
    path: "/",
    exact: true,
    component: () => <Root />,
    route: Route,
};

const authRoutes: RoutesProps[] = [
    {
        path: "/ia/:email",
        name: "Generate",
        component: CollovGbt,
        route: Route,
    },
    {
        path: "/ia",
        name: "Generate",
        component: CollovGbt,
        route: Route,
    }
];

const otherPublicRoutes: RoutesProps[] = [
    {
        path: "/error-404",
        name: "Error - 404",
        component: Error404,
        route: Route,
    },
    {
        path: "/error-500",
        name: "Error - 500",
        component: Error500,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== "undefined") {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [
    rootRoute
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
    publicRoutes,
    authProtectedRoutes,
    authProtectedFlattenRoutes,
    publicProtectedFlattenRoutes,
};

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

const Root = () => {

    const api = new APICore();

    const getRootUrl = () => {
        let url: string = 'ia';
        return url;
    };

    const url = getRootUrl();
    return <Redirect to={`/${url}`} />;
};

export default Root;

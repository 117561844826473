import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import logo from '../../assets/images/logo-bg-2.png';
import logo1 from '../../assets/images/logo-bg.png';
import config from '../../config';
import { APICore } from '../../helpers/api/apiCore';
import FeatherIcons from 'feather-icons-react';
import { isMobile } from 'react-device-detect';
import twitter from '../../assets/images/social/twitter.png'
import whatsapp from '../../assets/images/social/whatsapp.png'
import tictok from '../../assets/images/social/tic-tac.png'
import snapshat from '../../assets/images/social/snapchat.png'
import instagram from '../../assets/images/social/instagram.png'

interface AccountLayoutProps {
    bottomLinks?: any;
    children?: any;
    shopImage?: string;
    openMenu?: any;
    user?: any;
    logout?: any;
}

const Layout = ({ bottomLinks, children, shopImage, openMenu, user, logout }: AccountLayoutProps) => {

    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, []);
    const history = useHistory();

    return (
        <>

            <div className="account-pages" style={{ backgroundColor: 'white' }}>
                <Container>
                    {isMobile &&
                        <div style={styles.container12}>
                            <div style={{ flex: 0.5 }}>
                                <FeatherIcons style={{ height: 25 }} icon="menu" onClick={() => openMenu()} />
                            </div>
                            <img src={logo1} alt="" style={{
                                width: 100,
                                height: '75%',
                                objectFit: 'contain',
                                flex: 1,
                            }} />
                            {!user && <p style={{ flex: 0.5, textAlign: 'left' }} className="h6" onClick={() => window.location.href = "https://manzilikom.com/index.php?route=account/login"}>تسجيل الدخول</p>}
                            {user && <div style={{ flex: 0.5}} /> }
                            {/* {user &&
                                <div style={{ flex: 0.5, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <p style={{ textAlign: 'left', paddingLeft: 10 }} className="h6" onClick={() => window.location.href = "https://manzilikom.com"}>عودة</p>
                                    <FeatherIcons style={{ height: 25 }} icon="shopping-bag" onClick={() => openMenu()} />
                                </div>
                            } */}
                        </div>
                    }
                    <Row className="justify-content-center" style={{ paddingTop: 70 }}>
                        <Col xl={10}>
                            <Card>
                                <Card.Body className="p-0">
                                    <Row className="g-0">
                                        {!isMobile &&
                                            <>
                                                <Col lg={6} className="d-flex mt-1" style={{ paddingRight: 10 }}>
                                                    <FeatherIcons style={{ height: 30, width: 30, paddingLeft: 5 }} icon="menu" onClick={() => openMenu()} />
                                                </Col>
                                                <Col lg={6} className="d-flex justify-content-end">
                                                    <Button onClick={() => user ? logout() : window.location.href = "https://manzilikom.com/index.php?route=account/login"} style={{ backgroundColor: 'transparent', border: '1px #000 dashed', color: 'black', margin: 5, alignSelf: 'flex-end' }}>
                                                        {user ? "تسجيل الخروج" : "تسجيل الدخول"}
                                                    </Button>
                                                </Col>
                                            </>
                                        }
                                        {/* <Col lg={12} className="d-flex justify-content-center mt-2">
                                            <img src={logo} alt="" style={{
                                                width: 150,
                                                height: 150,
                                                objectFit: 'contain'
                                            }} />
                                        </Col> */}
                                        <Col lg={12} className="p-2 d-flex flex-column justify-content-center">
                                            {children}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}

                            <div style={styles.container13}>
                                <div className="h6 mb-1 mt-1">
                                    <img alt="" src={whatsapp} style={{ height: 18, width: 18, marginRight: 5 }} />
                                    <img alt="" src={tictok} style={{ height: 20, width: 20, marginRight: 5 }} />
                                    <img alt="" src={snapshat} style={{ height: 18, width: 18, marginRight: 5 }} />
                                    <img alt="" src={instagram} style={{ height: 18, width: 18, marginRight: 5 }} />
                                    <img alt="" src={twitter} style={{ height: 16, width: 16, marginRight: 5 }} />
                                </div>
                                <p className="h6 mb-1 mt-1">Copyright <b>Manzilikom.com 2023</b></p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Layout;

const styles = {
    container12: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#F5F5F5",
        position: 'fixed' as const,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        height: 60,
        paddingLeft: 10,
        paddingRight: 10
    },
    container13: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10
    },
    container14: {
        display: 'flex',
        flexDirection: 'row' as const,
        paddingLeft: 10,
        paddingRight: 10,
        alignSelf: 'center',
        justifyContent: 'center',
    }
}
import { isMobile, isTablet, isDesktop } from "react-device-detect"

export const IS_VALID_EMAIL = (email) => {
    var pattern = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
    return pattern.test(email)
}

export const IS_VALID_PHONE_NUMBER = (phoneNumber) => {
    if(phoneNumber.length === 9){
        return true
    }
    var pattern = new RegExp(/^[\+]?[(]?[0-9]{4}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im)
    return pattern.test(phoneNumber)
}

export const IS_MOBILE = isMobile
export const IS_TABLET = isTablet
export const IS_DESKTOp = isDesktop
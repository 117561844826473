import Routes from './routes/Routes';
import './assets/scss/Theme.scss';

const App = () => {

    return (
        <Routes></Routes>
    );
};

export default App;

import { all, fork, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { CommonActionTypes } from "./constants";

interface ShopData {
  payload: {
    name: string;
  };
  type: string;
}

function* getShop({
  payload: { name },
  type,
}: ShopData): SagaIterator {
  try {
  } catch (error: any) {
    alert(error);
  }
}

export function* watchGetShop() {
  yield takeEvery(CommonActionTypes.GET_SHOP, getShop);
}

function* commonSaga() {
  yield all([fork(watchGetShop)]);
}

export default commonSaga;

import { Button, Col, FormLabel, Modal, Offcanvas, Row } from 'react-bootstrap';
import FeatherIcons from 'feather-icons-react';
import { FormInput } from '../../components';
import Layout from './Layout';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { CircularProgress, LinearProgress } from '@mui/material';
import { APICore } from '../../helpers/api/apiCore';
import { useHistory } from 'react-router';
import { BASIC_URL } from '../../config';
import logo from '../../assets/images/logo-bg-2.png';
import fileDownload from 'js-file-download'
import { isMobile } from 'react-device-detect';
import './style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IS_VALID_EMAIL } from '../../helpers/common';

const CollovGbt = (props: any) => {

    let apiCore = new APICore()

    useEffect(() => {
        getApiToken()
    }, [])

    useEffect(() => {
        if (window.screen.orientation) {
            window.screen.orientation.onchange = function (e) {
                setFurnituresCoordinates([])
                setSmiliarProducts([])
                setSelectedFurniture(null)
                setDownloading(false)
                setUpScaling(false)
                setSelectedImageIndex(-1)
                setSelectedHistoryImageIndex(-1)
            }
        }
    }, [])

    const [style, setStyle] = useState({ value: "", label: "" })
    const [roomType, setRoomType] = useState("")
    const [isGenerating, setGenerating] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)
    const [showImagesHistory, setShowImagesHistory] = useState(false)
    const [isGeneratingFurnitureMarks, setGeneratingFurnitureMarks] = useState(false)
    const [isDownloading, setDownloading] = useState(false)
    const [isUpScaling, setUpScaling] = useState(false)
    const [isCanUpscale, setCanUpscale] = useState(false)

    const [images, setImages] = useState([])
    const [file, setFile] = useState<any>(null)
    const [furnituresCoordinates, setFurnituresCoordinates] = useState<any[]>([])
    const [newStyles, setNewStyles] = useState<any[]>([])
    const [customerHistoryImages, setCustomerHistoryImages] = useState<any[]>([])
    const [selectedImageIndex, setSelectedImageIndex] = useState(-1)
    const [selectedHistoryImageIndex, setSelectedHistoryImageIndex] = useState(-1)
    const [user, setUser] = useState<any>(null)
    const history = useHistory();
    const [similarProducts, setSmiliarProducts] = useState<string[]>([]);
    const [isSearchingSimilarProducts, setSearchingSimilarProducts] = useState(false)
    const [isUpdating, setUpdating] = useState(false)
    const [isAlreadyUpscaled, setAlreadyUpScaled] = useState(false)
    const [isCheckingAuth, setCheckingAuth] = useState(true)
    const [selectedFurniture, setSelectedFurniture] = useState<any>(null)
    const [recordId, setRecordId] = useState<number>(0)
    const [upscaledImageUrl, setUpscaledImageUrl] = useState<string>("")

    const imgRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar()
    const roomTypesData = [
        { value: "living room", label: "غرفة جلوس", image: "https://d17axom7zrjq3q.cloudfront.net/20200325/541afad3b0bd4faebbdef53359b5f01f.jpg" },
        { value: "dining room", label: "غرفة طعام", image: "https://d17axom7zrjq3q.cloudfront.net/20200324/4be4367c9f294b77a5ba528a7a01a06e.jpg" },
        { value: "bedroom", label: "غرفة نوم", image: "https://d17axom7zrjq3q.cloudfront.net/20200325/935f7201d03c49b78de07cc7821820b1.jpg" },
        { value: "children's room", label: "غرفة اطفال", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/childrens%20room.jpg" },
        { value: "family room", label: "غرفة المعيشة", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/family%20room.jpg" },
        { value: "bathroom", label: "حمام", image: "https://d17axom7zrjq3q.cloudfront.net/20200325/aa6061d9c8b547aba9207b3286b9e86d.png" },
        { value: "kitchen", label: "مطبخ", image: "https://d17axom7zrjq3q.cloudfront.net/20200325/7f26d451063746b3966abcf51b845639.png" },
        { value: "office", label: "مكتب منزلي", image: "https://d17axom7zrjq3q.cloudfront.net/20200324/a8f982afa8d34f51a6dd73fb29381f27.jpg" },
        { value: "hallway", label: "ممر المنزل", image: "https://d17axom7zrjq3q.cloudfront.net/20200709/e4aaccd911f4493fb3fd9fdffb8486ec.jpg" },
        { value: "entrance hall", label: "مدخل المنزل", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/entrance%20hall.jpg" },
        { value: "storage room", label: "غرفة التخزين", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/storage%20room.jpg" },
        { value: "cloak room", label: "غرفة ملابس", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/cloak%20room.jpg" },
        { value: "open office area", label: "مكتب مفتوح", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/open%20office.jpg" },
        { value: "meeting room", label: "غرف الاجتماعات", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/meeting%20rooms.jpg" },
        { value: "reception area", label: "الاستقبال", image: "https://d17axom7zrjq3q.cloudfront.net/image/roomType/reception.jpg" },
    ]
    const _styles = [
        { value: "bohemian", label: "البوهيمي", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/bohemian.jpg" },
        { value: "coastal", label: "ساحلية", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/coastal.jpg" },
        { value: "mid-century", label: "قرن", image: "https://d17axom7zrjq3q.cloudfront.net/20200324/dd71697a0f464a8f819c9e80c53baff3.jpg" },
        { value: "farmhouse", label: "بيت ريفي", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/farmhouse.jpg" },
        { value: "scandinavian", label: "اسكندنافي", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/scandinavian.jpg" },
        { value: "eclectic", label: "انتقائي", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/eclectic.jpg" },
        { value: "modern", label: "حديث", image: "https://d17axom7zrjq3q.cloudfront.net/20200325/8f117606f5d148708b28122acf7698e9.jpg" },
        { value: "transitional", label: "انتقالية", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/transitional.jpg" },
        { value: "rustic", label: "ريفي", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/rustic.jpg" },
        { value: "minimalism", label: "كلاسيك", image: "https://d17axom7zrjq3q.cloudfront.net/image/style2/minimalism.jpg" },
    ]
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const dropContainer = document.getElementById("dropcontainer")
    const fileInput = document.getElementById("images") as HTMLInputElement

    if (dropContainer) {
        dropContainer!.addEventListener("dragover", (e) => {
            e.preventDefault()
        }, false)

        dropContainer!.addEventListener("dragenter", () => {
            dropContainer!.classList.add("drag-active")
        })

        dropContainer!.addEventListener("dragleave", () => {
            dropContainer!.classList.remove("drag-active")
        })

        dropContainer!.addEventListener("drop", (e) => {
            e.preventDefault()
            dropContainer!.classList.remove("drag-active")
            if (fileInput) {
                fileInput.files! = e.dataTransfer!.files
            }
        })
    }

    async function getApiToken() {

        await axios.get(BASIC_URL + "api/api-token")
            .then(response => {
                if (response.data.api_token) {
                    localStorage.setItem("api_token", response.data.api_token);
                    let email = props.match?.params?.email || ""
                    if (email && IS_VALID_EMAIL(email)) {
                        getCustomerByEmail(email)
                        getCustomerHistory()
                    } else {
                        checkAuth()
                    }
                } else {
                    setCheckingAuth(false)
                }
            })
            .catch(function (error) { })
    }

    function checkAuth() {

        let isUserAuthenticated = apiCore.isUserAuthenticated()
        if (isUserAuthenticated) {
            let customer = apiCore.getLoggedInUser()
            getCustomerByEmail(customer.email)
            getCustomerHistory()
        } else {
            setCheckingAuth(false)
        }
    }

    async function saveCustomerHistory(photos_url: string, recordId: number) {

        let apiToken = localStorage.getItem("api_token")

        let data = {
            customer_id: user?.customer_id,
            photos_url: photos_url,
            apiToken: apiToken,
            record_id: recordId
        }
        await axios.post(BASIC_URL + "api/customer/history/new", data)
            .then(response => {
                getCustomerHistory()
            })
            .catch(function (error) {
            })
    }

    async function getCustomerHistory() {

        let apiToken = localStorage.getItem("api_token")

        let customer = apiCore.getLoggedInUser()
        let data = {
            idCustomer: customer.customer_id,
            apiToken: apiToken
        }
        await axios.post(BASIC_URL + "api/customer/history/all", data)
            .then(response => {
                let imagesUrls: any[] = []
                response.data.history.forEach((item: any) => {
                    item.photos_url.split(";").forEach((image: string) => {
                        imagesUrls.push({ url: image, recordId: item.record_id })
                    });
                })
                setCustomerHistoryImages(imagesUrls)
            })
            .catch(function (error) { })
    }

    async function getCustomerByEmail(email: string) {

        let apiToken = localStorage.getItem("api_token")
        let data = {
            email: email,
            apiToken: apiToken
        }
        await axios.post(BASIC_URL + "api/customer", data)
            .then(response => {
                let customer = response.data.customer
                if (customer) {
                    apiCore.setLoggedInUser(customer)
                    setUser(customer)
                    history.replace('/ia')
                }
            })
            .catch(function (error) {
            })
        setUpdating(false)
        setCheckingAuth(false)
    }

    const send = async () => {


        // getGeneratedImages(9291)
        // return

        if (!user) {
            handleToast("سجل الدخول أولا", "error")
            return
        }

        if (file === null) {
            handleToast("اختر صورة الغرفة", "error")
            return
        }

        if (style.value === "") {
            handleToast("اختر ستايل غرفتك", "error")
            return
        }

        if (roomType === "") {
            handleToast("اختر نوع الغرفة", "error")
            return
        }

        // if (mode.value === null) {
        //     handleToast("اختر الوضع", "error")
        //     return
        // }

        setImages([])
        setRecordId(0)
        setAlreadyUpScaled(false)
        setGenerating(true)
        setTimeout(() => {
            const scrollingElement = (document.scrollingElement || document.body);
            scrollingElement.scrollTop = scrollingElement.scrollHeight;
        }, 500)
        var formDataa = new FormData();
        formDataa.append('image', file);
        const result = await axios.post(BASIC_URL + 'upload', formDataa, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (!result?.data?.url) {
            handleToast('هناك مشكلة في رفع الصورة، المرجو المحاولة مجددا', "error")
            return
        }
        const formData = new FormData();
        formData.append("style", style.value)
        formData.append("roomType", roomType)
        formData.append("uploadUrl", result.data.url)
        // formData.append("requestId", style.requestId)
        formData.append("mode", "PRESERVED")
        generateImageApi(formData)
    };

    function handleToast(text: any, type: any) {

        enqueueSnackbar(text, { variant: type, anchorOrigin: { vertical: 'top', horizontal: 'right' } })
    }

    async function generateImageApi(data: object) {

        await axios.post("https://api.collov.ai/flair/enterpriseApi/collovAi/generateImage", data, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                setRecordId(response.data.data.id)
                getGeneratedImages(response.data.data.id)
            })
            .catch(function (error) {
                handleToast(error.response?.data?.message, "error")
                setGenerating(false)
            })
    }

    async function getColloVStyles() {

        let url = "https://api.collov.ai/flair/enterpriseApi/collovAi/getAllStyleList"
        await axios.get(url, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setNewStyles(response.data.data.map((item: any) => ({ value: item.value, label: item.name, image: item.url })))
            })
            .catch(function (error) {
            })
    }

    async function getUpscaledImageUrl(id: number, imgUrl: string) {

        let url = "https://api.collov.ai/flair/enterpriseApi/collovAi/getUpScale?id=" + id + "&imgUrl=" + imgUrl
        await axios.get(url, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                //console.log(response)
                if (response.data?.data?.generateUrl) {
                    //console.log("Upscaled image url received")
                    //console.log(response.data?.data)
                    setUpscaledImageUrl(response.data?.data?.generateUrl)
                } else {
                    setTimeout(() => {
                        //console.log("Getting upscaled image url")
                        getUpscaledImageUrl(id, imgUrl)
                    }, 2000)
                }
            })
            .catch(function (error) { })
    }

    async function getGeneratedImages(id: number) {

        let url = "https://api.collov.ai/flair/enterpriseApi/collovAi/getRecord?id=" + id
        await axios.get(url, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.data.status === "PENDING") {
                    setTimeout(() => {
                        getGeneratedImages(id)
                    }, 5000)
                } else if (response.data.data.status === "SUCCESS") {
                    setImages(JSON.parse(response.data.data.images))
                    decreaseCustomerBalance()
                    let images_urls = ""
                    JSON.parse(response?.data?.data?.images).map((image: any, index: number) => {
                        if (JSON.parse(response?.data?.data?.images).length - 1 === index) {
                            images_urls += image
                        } else {
                            images_urls += image + ";"
                        }
                    })
                    saveCustomerHistory(images_urls, id)
                    setGenerating(false)
                    setTimeout(() => {
                        const scrollingElement = (document.scrollingElement || document.body);
                        scrollingElement.scrollTop = scrollingElement.scrollHeight;
                    }, 1500)
                } else {
                    setGenerating(false)
                }
            })
            .catch(function (error) {
                handleToast(error.response?.data?.message, "error")
                setGenerating(false)
            })
    }

    async function getImageFurnitureDetails(url: string, index: number, width: any) {

        let _url = "https://api.collov.ai/flair/enterpriseApi/collovAi/listPosition?width=" + width + "&filePath=" + url
        await axios.get(_url, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                setFurnituresCoordinates(response.data.data)
            })
            .catch(function (error) {
            })
        setGeneratingFurnitureMarks(false)
    }

    async function getSimilarFurniture(type: string, secondaryType: string) {

        setSearchingSimilarProducts(true)

        let formData = new FormData()
        formData.append("type", type)
        formData.append("secondaryType", secondaryType)
        formData.append("count", "10")
        await axios.post("https://api.collov.ai/flair/enterpriseApi/collovAi/listFurnitureForNewAi", formData, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response: any) => {
                setSmiliarProducts(response.data.data.list)
            })
            .catch(function (error) {
            })
        setSearchingSimilarProducts(false)
    }

    async function requestImageUpScaling(id: number, index: number) {

        let formData = new FormData()
        formData.append("id", id + "")
        formData.append("imgUrl", images[index])
        await axios.post("https://api.collov.ai/flair/enterpriseApi/collovAi/getUpScaleUrlByImg", formData, {
            headers: {
                'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E',
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response: any) => {
                getUpscaledImageUrl(id, images[index])
            })
            .catch(function (error) {
            })
    }

    async function decreaseCustomerBalance() {

        let apiToken = localStorage.getItem("api_token");

        let data = {
            customer_id: user?.customer_id,
            apiToken: apiToken
        }

        await axios.post(BASIC_URL + "api/decrease-balance", data)
            .then(response => {
                let new_balance = response.data.new_balance
                if (new_balance) {
                    let _user = { ...user, ia_balance: new_balance }
                    setUser(_user)
                    apiCore.setLoggedInUser(_user)
                } else {
                    handleToast(response.data.error, "error")
                }
            })
            .catch(function (error) {
            })
    }

    async function upscaleGeneratedImage() {

        setUpScaling(true)
        // handleDownload(upscaledImageUrl, new Date().getTime())
        download(upscaledImageUrl)

        // await axios.get("https://api.collov.ai/flair/enterpriseApi/postsGenerateRecord/getUpScaleUrl?id=" + recordId + "&type=aiGenerate&size=4x", {
        //     headers: {
        //         'apiKey': 'ck_5972F11907C5A474EC3E1DA3C15CCF3E'
        //     }
        // })
        //     .then(response => {

        //         if (response.data.data) {
        //             handleDownload(response.data.data, new Date().getTime())
        //         } else {
        //             handleToast("حدث خطأ غير متوقع.", "error")
        //             setUpScaling(false)
        //         }
        //     })
        //     .catch(function (error) {
        //         setUpScaling(false)
        //     })
    }

    async function onChangeImage(event: any) {
        if (!event.target.files) {
            event.target.value = null;
            return
        }
        console.log(event.target.files[0])
        setFile(event.target.files[0])
        let _isCanUpscale = await checkUpScalePossibility(event.target.files[0])
        setCanUpscale(_isCanUpscale as boolean)
        //console.log("_isCanUpscale", _isCanUpscale)
    }

    const handleImageLoad = () => {
        let image = document.getElementById('imgg')
        setGeneratingFurnitureMarks(true)
        getImageFurnitureDetails(selectedHistoryImageIndex !== -1 ? customerHistoryImages[selectedHistoryImageIndex].url : images[selectedImageIndex], selectedHistoryImageIndex !== -1 ? selectedHistoryImageIndex : selectedImageIndex, image?.clientWidth)
    };

    const getSimilarProductsBoxHeight = () => {
        let box = document.getElementById('similar_products')
        return box?.clientHeight || 0
    };

    const normalDownload = async (url: string, filename: number) => {

        setDownloading(true)
        await axios.get(url, { responseType: 'blob' })
            .then((res) => {
                if (res) {
                    fileDownload(res.data, filename + ".png")
                }
                setDownloading(false)
            })
        setDownloading(false)
    }

    function checkUpScalePossibility(_file: any) {

        const img = document.createElement("img");
        const promise = new Promise((resolve, reject) => {
            img.onload = () => {
                const width = img.naturalWidth;
                resolve(width <= 1080);
            };
            img.onerror = reject;
        });
        img.src = URL.createObjectURL(_file);
        return promise;
    }

    async function download(url: string) {

        const urlToDownload = BASIC_URL + "download?url=" + encodeURIComponent(url);
        const a = document.createElement("a");
        a.href = await toDataURL(urlToDownload);
        a.download = new Date().getTime() + ".png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloading(false)
        setUpScaling(false)
    }

    function toDataURL(url: string) {

        return fetch(url)
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                return URL.createObjectURL(blob);
            });
    }

    async function handleDownload(url: string, fileName: number) {

        axios({
            url,
            method: 'GET',
            responseType: 'blob',
            timeout: 999999999999999,
            maxContentLength: 2000000000,
            maxRedirects: 50
        })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);
                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = fileName.toString();

                if (typeof anchorElement.download === 'undefined') {
                    anchorElement.setAttribute('target', '_blank');
                }
                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                setDownloading(false)
                setUpScaling(false)
            })
            .catch(async (error) => {
                setDownloading(false)
                setUpScaling(false)
            });
    }

    function hideModal() {

        setSelectedImageIndex(-1)
        setSelectedHistoryImageIndex(-1)
        setSelectedFurniture(null);
        setFurnituresCoordinates([]);
        setSmiliarProducts([]);
        setUpscaledImageUrl("")
        setDownloading(false);
        setUpScaling(false)
    }

    function resetStates() {

        setStyle({ value: "", label: "" })
        setRoomType("")
        fileInput!.value = ""
        setFile(null)
        setImages([])
        setRecordId(0)
        setAlreadyUpScaled(false)
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = 0
    }

    function logout() {
        apiCore.setLoggedInUser(null)
        setUser(null)
        setShowMenu(false)
    }

    if (isCheckingAuth) {
        return (
            <Layout openMenu={() => setShowMenu(true)} user={user}>
                <CircularProgress style={{ alignSelf: 'center' }} color="info" />
            </Layout >
        );
    }

    return (
        <>
            <Layout openMenu={() => setShowMenu(true)} user={user} logout={() => logout()}>

                <h4 style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: 15, color: "#5D290D" }}>صمم موقعك بالذكاء الاصطناعي</h4>
                {user &&
                    <div className="d-flex justify-content-space-between">

                        <div style={styles.container1}>
                            <h3 style={styles.container2}>مرحبا <b>{user?.firstname}</b></h3>
                            <Button
                                onClick={() => window.open("https://manzilikom.com/index.php?route=product/product&product_id=542&search=%D8%B0%D9%83%D8%A7%D8%A1", "_blank")}
                                style={styles.balanceButton}>
                                <span style={{ textAlign: 'center' }}>اشحن رصيدك</span>
                                {/* <span style={{
                                textAlign: 'center', fontWeight: 'bold', letterSpacing: -1
                            }}>SAR</span> */}
                                {/* <FeatherIcons style={{ height: 21, paddingTop: 2, color: "white" }} color="white" icon={'dollar-sign'} className="icon-dual" /> */}
                            </Button>
                        </div>

                        <div style={styles.container3}>
                            <h3 style={styles.container2}>الرصيد الحالي</h3>
                            <Button
                                style={{ ...styles.balanceButton, width: '100%' }}>
                                <span>{user?.ia_balance} نقطة</span>
                            </Button>
                            {isUpdating && <LinearProgress color="error" style={{ height: 5 }} />}
                            <Button
                                onClick={() => {
                                    setUpdating(true)
                                    let customer = apiCore.getLoggedInUser()
                                    getCustomerByEmail(customer.email)
                                }}
                                className="mt-1"
                                style={styles.updateButton}>
                                <span style={{ textAlign: 'center', flex: 1 }}>تحديث</span>
                                <FeatherIcons style={{ height: 21, paddingTop: 2 }} icon={'refresh-ccw'} className="icon-dual" />
                            </Button>
                        </div>
                        {/*                     

                    <div style={styles.container3}>
                        <p style={{ fontSize: 16, textAlign: 'center' }}>الرصيد الحالي {isMobile ? <br /> : null}</p>
                        <p style={{ fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, textAlign: 'center', backgroundColor: '#5D290D', color: 'white' }}>{user?.ia_balance} نقطة</p>
                       
                        <Button
                            onClick={() => {
                                setUpdating(true)
                                let customer = apiCore.getLoggedInUser()
                                getCustomerByEmail(customer.email)
                            }}
                            style={styles.updateButton}>
                            <span style={{ textAlign: 'center', flex: 1 }}>تحديث</span>
                            <FeatherIcons style={{ height: 21, paddingTop: 2 }} icon={'refresh-ccw'} className="icon-dual" />
                        </Button>
                        {/* <Button
                            onClick={() => window.open("https://manzilikom.com/index.php?route=product/product&product_id=542&search=%D8%B0%D9%83%D8%A7%D8%A1", "_blank")}
                            style={styles.balanceButton}>
                            <span style={{ textAlign: 'center', flex: 1 }}>اشحن</span>
                            <span style={{
                                textAlign: 'center', fontWeight: 'bold', letterSpacing: -1
                            }}>SAR</span>
                            {/* <FeatherIcons style={{ height: 21, paddingTop: 2, color: "white" }} color="white" icon={'dollar-sign'} className="icon-dual" /> */}
                        {/* </Button>  */}
                        {/* </div>  */}
                    </div>
                }

                <p className={"text-muted mb-2 mt-2"}> يرجى ملء النموذج بالمعلومات المطلوبة </p>

                <h6 className="h6 mb-3 mt-1">أرفق صورة</h6>

                <FormLabel htmlFor="images" className="drop-container" id="dropcontainer">
                    <h6 className="h6 mb-1 mt-3">قم بسحب و إسقاط الصورة هنا </h6>
                    <h6 className="h6 mb-1 mt-1">أو</h6>
                    <FormInput
                        type="file"
                        name="file"
                        id="images"
                        accept="image/*"
                        startIcon={<FeatherIcons icon={'image'} className="icon-dual" />}
                        containerClass={'mb-3'}
                        onChange={onChangeImage}
                    />
                    {file && <img alt="" src={URL.createObjectURL(file)} style={{ objectFit: "contain", borderRadius: 10, height: '100%', width: isMobile ? '100%' : '50%' }} />}
                </FormLabel>


                <h6 className="h6 mb-3 mt-3">نوع الغرفة</h6>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    rtl={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    itemClass="itemStyle"
                    partialVisible={false}
                >
                    {roomTypesData.map(item =>
                        <div style={{
                            width: 150,
                            borderRadius: 20,
                            marginRight: 10,
                            overflow: 'hidden',
                            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                            border: item.value === roomType ? "5px solid #74331f" : "3px solid #fff"
                        }} onClick={() => setRoomType(item.value)}>
                            <img alt="" src={item.image} style={{
                                objectFit: "cover",
                                height: 100,
                                width: 150,
                            }} />
                            <p className="text-center" style={{ marginTop: 15 }}>{item.label}</p>
                        </div>
                    )}
                </Carousel>

                <h6 className="h5 mb-3 mt-3">اختر النمط</h6>
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    rtl={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    itemClass="itemStyle"
                    partialVisible={false}
                >
                    {_styles.map(item =>
                        <div style={{
                            width: 150,
                            borderRadius: 20,
                            marginRight: 10,
                            overflow: 'hidden',
                            boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                            border: item.label === style.label ? "5px solid #74331f" : "3px solid #fff"
                        }}
                            onClick={() => setStyle({ label: item.label, value: item.value })}>
                            <img alt="" src={item.image} style={{ objectFit: "cover", height: 100, width: 150 }} />
                            <p className="text-center" style={{ marginTop: 15 }}>{item.label}</p>
                        </div>
                    )}
                </Carousel>

                {/* <div style={styles.container11}>
                    {_styles.map(item =>
                        <div style={{
                            marginLeft: 10,
                            borderRadius: 20,
                            boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                            border: item.label === style.label ? "5px solid #74331f" : "3px solid #fff"
                        }}
                            onClick={() => setStyle({ label: item.label, value: item.value })}>
                            <img alt="" src={item.image} style={{ objectFit: "cover", height: 100, width: 150, borderRadius: 20, marginLeft: 0 }} />
                            <p className="text-center" style={{ marginTop: 15 }}>{item.label}</p>
                        </div>
                    )}
                </div> */}


                <div className={"mt-3 justify-content-center align-items-center text-center " + (images.length > 0 ? " mb-3" : "mb-1")}>

                    {images.length === 0 &&
                        <Button onClick={async () => {
                            if (parseInt(user?.ia_balance) === 0) {
                                handleToast("رصيدك غير كاف لإجراء العملية", "error")
                            } else {
                                send()
                            }
                        }} disabled={isGenerating} style={{
                            backgroundColor: '#74331f',
                            height: 60,
                            width: isMobile ? '100%' : '50%',
                            alignSelf: 'center',
                            borderRadius: 10,
                        }}>
                            أرسل
                        </Button>
                    }

                    {images.length > 0 &&
                        <Button onClick={() => resetStates()} style={{
                            backgroundColor: '#000',
                            marginTop: 5,
                            color: "#fff",
                            height: 50,
                            width: isMobile ? '100%' : '50%'
                        }}>
                            صورة جديدة
                        </Button>
                    }
                    {isGenerating && <p className="text-muted mt-3 mb-4 text-center">
                        تستغرق العملية دقيقتين , نرجو الانتظار ...
                    </p>}
                    {isGenerating && <LinearProgress color="error" style={{ width: '100%', height: 5 }} />}
                </div>

                {images.length > 0 &&
                    <Row>
                        <Col lg={6} xl={6} md={12}>
                            <img src={images[0]} style={{ width: '100%', marginBottom: isMobile ? 10 : 0 }} onClick={() => { setSelectedImageIndex(0); requestImageUpScaling(recordId, 0) }} />
                        </Col>
                        <Col lg={6} xl={6} md={12}>
                            <img src={images[1]} style={{ width: '100%' }} onClick={() => { setSelectedImageIndex(1); requestImageUpScaling(recordId, 1) }} />
                        </Col>
                    </Row>
                }

                {images.length > 0 &&
                    <Row className="mt-2">
                        <Col lg={6} xl={6} md={12}>
                            <img src={images[2]} style={{ width: '100%', marginBottom: isMobile ? 10 : 0 }} onClick={() => { setSelectedImageIndex(2); requestImageUpScaling(recordId, 2) }} />
                        </Col>
                        <Col lg={6} xl={6} md={12}>
                            <img src={images[3]} style={{ width: '100%' }} onClick={() => { setSelectedImageIndex(3); requestImageUpScaling(recordId, 3) }} />
                        </Col>
                    </Row>
                }

                <Modal size="xl" scrollable={false} show={selectedImageIndex !== -1 || selectedHistoryImageIndex !== -1} onHide={() => hideModal()} centered>
                    {isGeneratingFurnitureMarks && <LinearProgress color="error" style={{ height: 5 }} />}
                    {!isMobile &&
                        <div style={styles.container5}>

                            <div id="similar_products" style={styles.container6}>
                                {/* <img src={logo} style={{ objectFit: 'contain', width: '100%', height: 150 }} /> */}
                                {furnituresCoordinates.length > 0 && <p className="text-center" style={{ marginTop: 0 }}>اختر أثاث من الصورة للحصول على منتجات Manzilikom المشابهة</p>}
                                {similarProducts.length > 0 ?
                                    <div style={{
                                        ...styles.container7,
                                        height: getSimilarProductsBoxHeight() - 210
                                    }}>
                                        {similarProducts.map((product: any) => <div onClick={() => window.open(product.link, '_blank')!.focus()}
                                            style={styles.container9}>
                                            <img src={product.imageUrl} style={{ objectFit: 'fill', width: '100%', height: 160 }} />
                                            <h4 style={{ textAlign: 'center' }}>{product.name}</h4>
                                            <p style={{ textAlign: 'center' }}><b>{product.priceCents / 100}</b> ريال</p>
                                            <div style={{ width: '85%', height: 1, backgroundColor: '#d1d1d1' }} />
                                        </div>
                                        )}
                                    </div>
                                    : (isSearchingSimilarProducts
                                        ? <CircularProgress style={{ alignSelf: 'center' }} color="info" />
                                        : (selectedFurniture
                                            ? <p className="text-center" style={{ marginTop: 10, color: "#a1785c" }}>لا توجد منتجات مشابهة للأثاث الذي اخترت.</p>
                                            : null
                                        )
                                    )
                                }
                            </div>
                            <div style={{ width: '100%' }}>
                                <img
                                    alt=""
                                    id="imgg"
                                    ref={imgRef}
                                    style={styles.image}
                                    src={selectedHistoryImageIndex !== -1 ? customerHistoryImages[selectedHistoryImageIndex].url : images[selectedImageIndex]}
                                    onLoad={handleImageLoad}
                                />
                                {furnituresCoordinates.map((item, index) =>
                                    <a key={index} style={{
                                        height: 10,
                                        width: 10,
                                        borderRadius: 5,
                                        backgroundColor: 'white',
                                        position: 'absolute',
                                        top: item.y1 + (item.y2 - item.y1) / 2,
                                        left: item.x1 + (item.x2 + 3 - item.x1) / 2,
                                    }} href="javascript:void(0);" onClick={() => {
                                        setSmiliarProducts([])
                                        setSelectedFurniture(item)
                                        getSimilarFurniture(item.secondaryType.name, item.secondaryType.rid);
                                    }} />
                                )}
                                <div style={styles.container8}>

                                    <Button style={{ ...styles.button1, width: 150, marginTop: 5, marginLeft: 5 }} onClick={() => window.open("https://manzilikom.com/index.php?route=product/category&path=87", "_blank")}>
                                        صمم معنا
                                    </Button>

                                    {(isCanUpscale && upscaledImageUrl !== "") &&
                                        <Button style={{ ...styles.button1, width: 150, marginTop: 5, marginLeft: 5 }} onClick={() => {
                                            setUpScaling(true)
                                            download(upscaledImageUrl)
                                        }}>
                                            {isUpScaling
                                                ? <CircularProgress size={20} style={{ alignSelf: 'center' }} color="warning" />
                                                : "حمل دقة عالية"
                                            }
                                        </Button>
                                    }
                                    <div>
                                        <Button style={{ ...styles.button2, width: 150, marginTop: 5, marginLeft: 5 }} onClick={() => { setDownloading(true); download(selectedHistoryImageIndex !== -1 ? customerHistoryImages[selectedHistoryImageIndex].url : images[selectedImageIndex]) }}>
                                            {isDownloading
                                                ? <CircularProgress size={20} style={{ alignSelf: 'center' }} color="warning" />
                                                : "تحميل الصورة"
                                            }
                                        </Button>
                                    </div>

                                    <div>
                                        <Button
                                            onClick={() => hideModal()}
                                            style={{ ...styles.button3, width: 100 }}>
                                            <span style={{ textAlign: 'center', flex: 1 }}>إغلاق</span>
                                            <FeatherIcons style={{ height: 21, paddingTop: 2, color: "white" }} icon={'x'} className="icon-dual" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {isMobile &&
                        <div style={{ ...styles.container5, flexDirection: 'column' }}>

                            <div style={{ width: '100%' }}>
                                <Button
                                    onClick={() => hideModal()}
                                    style={styles.closeButton}>
                                    <FeatherIcons style={{ height: 21, paddingTop: 2, color: "black" }} icon={'x'} className="icon-dual" />
                                </Button>
                                {/* <img alt="" src={logo} style={{ objectFit: 'contain', width: '90%', height: 70, marginTop: 15, marginBottom: 15 }} /> */}
                                <img
                                    alt=""
                                    id="imgg"
                                    ref={imgRef}
                                    style={styles.image}
                                    src={selectedHistoryImageIndex !== -1 ? customerHistoryImages[selectedHistoryImageIndex].url : images[selectedImageIndex]}
                                    onLoad={handleImageLoad}
                                />
                                {furnituresCoordinates.map((item, index) =>
                                    <a key={index} style={{
                                        height: 10,
                                        width: 10,
                                        borderRadius: 5,
                                        backgroundColor: 'white',
                                        position: 'absolute',
                                        top: item.y1 + (item.y2 - item.y1) / 2 + 100,
                                        left: item.x1 + (item.x2 + 3 - item.x1) / 2,
                                    }} href="javascript:void(0);" onClick={() => {
                                        setSmiliarProducts([])
                                        setSelectedFurniture(item)
                                        getSimilarFurniture(item.secondaryType.name, item.secondaryType.rid);
                                    }} />
                                )}
                                <div style={styles.container10}>
                                    <Button style={{ ...styles.button2, width: '90%', marginBottom: 5 }} onClick={() => { setDownloading(true); download(selectedHistoryImageIndex !== -1 ? customerHistoryImages[selectedHistoryImageIndex].url : images[selectedImageIndex]) }}>
                                        {isDownloading
                                            ? <CircularProgress size={20} style={{ alignSelf: 'center', marginTop: 5 }} color="warning" />
                                            : "تحميل الصورة"
                                        }
                                    </Button>
                                    {(isCanUpscale && upscaledImageUrl !== "") &&
                                        <Button style={{ ...styles.button1, width: '90%' }} onClick={() => {
                                            if (isAlreadyUpscaled) {
                                                enqueueSnackbar("لا يمكنك التحميل مرة أخرى بدقة عالية، ارفع صورة جديدة.", { variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }, autoHideDuration: 5000 })

                                                return
                                            } else {
                                                setUpScaling(true)
                                                download(upscaledImageUrl)
                                            }
                                        }}>
                                            {isUpScaling
                                                ? <CircularProgress size={20} style={{ alignSelf: 'center', marginTop: 5 }} color="warning" />
                                                : "حمل دقة عالية"
                                            }
                                        </Button>
                                    }
                                    <Button style={{ ...styles.button2, width: '90%' }} onClick={() => window.open("https://manzilikom.com/index.php?route=product/category&path=87", "_blank")}>
                                        صمم معنا
                                    </Button>
                                </div>
                            </div>
                            <div id="similar_products" style={{ ...styles.container6, width: '100%' }}>
                                {furnituresCoordinates.length > 0 && <p className="text-center" style={{ marginTop: 10 }}>اختر أثاث من الصورة للحصول على منتجات Manzilikom المشابهة</p>}
                                {similarProducts.length > 0 ?
                                    <div style={{
                                        ...styles.container7,
                                        height: getSimilarProductsBoxHeight() - 155
                                    }}>
                                        {similarProducts.map((product: any) => <div onClick={() => window.open(product.link, '_blank')!.focus()}
                                            style={styles.container9}>
                                            <img src={product.imageUrl} style={{ objectFit: 'contain', width: '100%', height: 160 }} />
                                            <h4 style={{ textAlign: 'center' }}>{product.name}</h4>
                                            <p style={{ textAlign: 'center' }}><b>{product.priceCents / 100}</b> ريال</p>
                                            <div style={{ width: '85%', height: 1, backgroundColor: '#d1d1d1' }} />
                                        </div>
                                        )}
                                    </div>
                                    : (isSearchingSimilarProducts
                                        ? <CircularProgress style={{ alignSelf: 'center' }} color="info" />
                                        : (selectedFurniture
                                            ? <p className="text-center" style={{ marginTop: 10, color: "#a1785c" }}>لا توجد منتجات مشابهة للأثاث الذي اخترت.</p>
                                            : null
                                        )
                                    )
                                }
                            </div>
                        </div>
                    }
                </Modal>

                <Offcanvas show={showMenu} onHide={() => setShowMenu(false)} placement="end" style={{ height: '100%', width: isMobile ? '70%' : '25%' }} >
                    <Offcanvas.Header style={{
                        justifyContent: 'space-between',
                        padding: 0,
                        paddingRight: 10,
                        paddingLeft: 10
                    }}>
                        <div />
                        {/* <img src={logo} alt="" style={{ width: 100, height: 100, objectFit: 'contain' }} /> */}
                        <FeatherIcons style={{ height: 21 }} icon={'x'} className="icon-dual" onClick={() => setShowMenu(false)} />
                    </Offcanvas.Header >
                    <Offcanvas.Body>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => window.open("https://manzilikom.com/", "_blank")}>
                            <FeatherIcons style={{ height: 21, paddingLeft: 5 }} icon="shopping-bag" onClick={() => setShowMenu(false)} />
                            <p style={{ fontWeight: 'bold', marginRight: 3 }}>المتجر</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => window.open("https://manzilikom.com/index.php?route=product/product&product_id=542&search=%D8%B0%D9%83%D8%A7%D8%A1", "_blank")}>
                            <span style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 13, letterSpacing: -2, paddingLeft: 4 }}>SAR</span>
                            <p style={{ fontWeight: 'bold', marginRight: 3 }}>اشحن رصيدك</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => { setShowMenu(false); setShowImagesHistory(true) }}>
                            <FeatherIcons style={{ height: 21, paddingLeft: 5 }} icon="image" />
                            <p style={{ fontWeight: 'bold', marginRight: 3 }}>أرشيف الصور</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => { setShowMenu(false); setShowInstructions(true) }}>
                            <FeatherIcons style={{ height: 21, paddingLeft: 5 }} icon="info" onClick={() => setShowMenu(false)} />
                            <p style={{ fontWeight: 'bold', marginRight: 3 }}>تعليمات استخدام الذكاء</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => user ? logout() : window.location.href = "https://manzilikom.com/index.php?route=account/login"}>
                            <FeatherIcons style={{ height: 21, paddingLeft: 5 }} icon="log-out" />
                            <p style={{ fontWeight: 'bold', marginRight: 3 }}>{user ? "تسجيل الخروج" : "تسجيل الدخول"}</p>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showInstructions} onHide={() => setShowInstructions(false)} placement="bottom" style={{ height: isMobile ? '100%' : '95%', width: '100%' }} >
                    <Offcanvas.Header style={{
                        justifyContent: 'space-between',
                        padding: 10
                    }}>
                        <div />
                        <FeatherIcons style={{ height: 26, windth: 26 }} icon={'x'} className="icon-dual" onClick={() => setShowInstructions(false)} />
                    </Offcanvas.Header >
                    <Offcanvas.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* <img src={logo} alt="" style={{ width: 200, height: 200, objectFit: 'contain' }} /> */}
                        <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: isMobile ? 20 : 0, paddingBottom: 15, color: "#5D290D" }}>تعليمات استخدام الذكاء</h2>
                        <p className={"mb-2 mt-4"} style={{ fontSize: 19, paddingLeft: 10, paddingRight: 10 }}>
                            هل تواجه صعوبة في الحصول على أفكار تصميم جديدة لمساحتك ؟
                            <br /> هل تريد تصاميم تعكس شخصيتك و استقلاليتك ؟ هل أطلت البحث عن اختيارات تلبي كافة احتياجاتك؟ أو ربما لا تمتلك ميزانية كافية للاستعانة بمصمم في كافة مساحات الديكور الخاصة بك ؟
                            <br /><br />
                            الان …
                            <br /><br />
                            تصميمك أصبح أسهل، اسرع ، أمتع، و باقل كلفة ماليه وذلك مع Manzilikom( قم بمتابعتنا عبر الانستقرام لتحصل على تجربتك مجانا لعدد ٥ نقاط )
                            <br /><br />
                            ابدأ الآن تجربتك بتصميم يحقق تصوراتك وبشكل  استثنائي مع Manzilikom  وذلك بخطوات سهلة وسريعة  .
                            <br /><br />
                            قم بالتقاط صورة او بتحميل مساحتك ، وحدد نوع الغرفة و النمط . ثم انتظر ما بين دقيقتين الي ٣ دقائق لتحصل  على مجموعة من التصورات التي تقربك لتصميمك.
                            عند اختيارك للتصميم الملامس لرغباتك قم بالضغط على الصوره المختارة ليظهر لك نقاط بيضاء على الاثاث قم بالضغط عليها لتظهر لك اسعار الاثاث المتوفره لدينا من خلال متجر منزلكم و شركاؤنا ان وجدت ،  حمل الصورة بدقة عاليه ومن ثم اكمل مشوارك بالضغط على زر طلب تصميم احترافي ليقوم النظام بأعادة توجيهك لمتجر منزلكم ومن ثم اختر من خلاله ( طلب تصميم خاص - اضغط عرض سعر ) او اختر من الخيارات المتاحه بالقسم بعدها سوف يقوم فريق العمل الاحترافي لدينا بالتواصل معك لنكمل معك رحلة التصميم و إجراء أي تعديل على التصاميم وعمل المخططات التنفيذية لتسهل عليك تنفيذها بجوده عليه تتوافق مع مخططاتك الماليه .
                            لا نزال مستمرين معك في تقديم جهودنا و خدماتنا حيث ان منزلكم تقوم من خلال فريقها المختص و المحترف بتنفيذ كافة الاعمال المطلوبه بتصميمك وفق معايير هندسية وجماليه تضفي على المكان جمال يفوق تصوراتك ( اطلع على اعمالنا من خلال مواقع التواصل الاجتماعيه الموجوده اسفل صفحة المتجر ) ايضا و من خلال متجر منزلكم يمكنك الاطلاع على الاثاث الذي سوف يقترحه عليك فريق العمل لدينا حيث ان متجر منزلكم يقوم بتصنيع الاثاث محلين والطلب خارجيا  بالتعاون مع شركاؤنا من  كبار المصانع و  الشراكات و المتاجر  التي تضمن لكم جوده و معايير  عاليه .
                            <br /> نحن في منزلكم و شركاؤنا نسعى دوما بتسخير  كافة جهودنا وخبراتنا …. نكفيكم به خطوات مرهقة من العمل و الاختيار الغير جيد و الملائم للاثاث و المواد والبحث عن منفذ محترف والذي ينتج بعدم حصولكم عليه تحمل مخرجات العمل فقدان جوده وجمال وفخامة مظهر اختياراتكم لتصميمكم .
                        </p>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showImagesHistory} onHide={() => setShowImagesHistory(false)} placement="bottom" style={{ height: isMobile ? '100%' : '95%', width: '100%' }} >
                    <Offcanvas.Header style={{
                        justifyContent: 'space-between',
                        padding: 10
                    }}>
                        <div style={{ width: '100%'}}>
                            <div style={{ padding: 10}}>
                                <div />
                                <FeatherIcons style={{ height: 26, windth: 26 }} icon={'x'} className="icon-dual" onClick={() => setShowImagesHistory(false)} />
                            </div >

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                {/* <img src={logo} alt="" style={{ width: 200, height: 150, objectFit: 'contain' }} /> */}
                                <h2 style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: 20, paddingBottom: 25, color: "#5D290D" }}>أرشيف الصور ({customerHistoryImages.length})</h2>
                            </div>
                        </div>

                    </Offcanvas.Header >
                    <Offcanvas.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <Row>
                            {customerHistoryImages.map((image: any, index) => <Col lg={4} xl={4} md={12}>
                                <img alt="" src={image.url} style={{ width: '100%', marginBottom: 10 }} onClick={() => { setSelectedHistoryImageIndex(index); requestImageUpScaling(image.recordId, index) }} />
                            </Col>
                            )}
                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>

            </Layout>
        </>
    );
};

const styles = {
    container1: { justifyContent: 'flex-start', flex: 1 },
    container2: {
        textAlign: 'right' as const,
        fontSize: 20,
        flex: 1
    },
    container3: { justifyContent: 'flex-start' },
    container5: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'center',
    },
    container6: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        width: 500
    },
    container7: {
        display: 'flex',
        flexDirection: 'row' as const,
        flexWrap: 'wrap' as const,
        overflow: 'scroll' as const,
        overflowX: 'hidden' as const,
        width: '100%'
    },
    container11: {
        display: 'flex',
        flexDirection: 'row' as const,
        overflow: 'scroll' as const,
        overflowY: 'hidden' as const,
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10
    },
    container8: {
        display: 'flex',
        flexDirection: 'row' as const,
        backgroundColor: 'transparent',
        position: 'absolute' as const,
        left: 0,
        marginTop: 5
    },
    container10: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginTop: 10,
        marginBottom: 5
    },
    button1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        color: "#74331f",
        marginBottom: 5
    },
    button2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        color: "#74331f"
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'contain' as const
    },
    container9: {
        width: '50%',
        padding: 10,
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center'
    },
    updateButton: {
        backgroundColor: '#fff',
        width: '100%',
        color: 'black',
        marginBottom: 5,
        border: '1px dashed #000',
        display: 'flex',
        justifyContent: 'space-between'
    },
    balanceButton: {
        backgroundColor: '#5D290D',
        border: '0px solid #000',
        fontWeight: 'bold' as const
    },
    button3: {
        backgroundColor: '#74331f',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 5
    },
    closeButton: {
        backgroundColor: '#fff',
        color: '#74331f',
        marginTop: 5,
        border: 0
    },
    container12: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10
    }
}
export default CollovGbt;